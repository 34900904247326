import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import en from "../langs/en.json"
import ro from "../langs/ro.json"
import { ApiLoginUser } from '../utils/RestServices';

import Lottie from 'react-lottie-player'

import styles from './LoginComponent.module.css'

import KeyboardComponent from './keyboard/KeyboardComponent';
import InputTextComponent from './keyboard/InputTextComponent';

import video from '../graphics/big wave fundal loop_6.mp4'

import LightButtonComponent from './commons/LightButtonComponent';

import enImg from '../graphics/en.svg'
import roImg from '../graphics/ro.svg'

import arrowLeft from '../graphics/arrow-left-animation.json'

const LoginComponent = () => {

    const ref = useRef()
    const inputGuestNameRef = useRef()
    const inputVisitorNameRef = useRef()
    const inputUserNameRef = useRef()
    const inputPasswordRef = useRef()
    const keyboardRef = useRef()

    const [passErrorMessage, setPassErrorMessage] = useState()

    const { socketRef, language, setLanguage, setCurrentUser, setProjectsList, setAllUsers, guestLogin, userLogin } = useContext(GlobalContext);



    function setGuestData() {
        if (guestLogin) {
            var body = JSON.stringify(guestLogin);
            ApiLoginUser(body, data => {
                setCurrentUser(data)
            });
        }
    }

    function setUserData() {

        if (inputVisitorNameRef.current.value) {
            var body = JSON.stringify({
                name: inputVisitorNameRef.current.value
            });

            ApiLoginUser(body, data => {
                if (data._id) {
                    setCurrentUser(data)
                } else {
                    setPassErrorMessage("Login error: user data or password wrong")
                }
            });
        } else {

            var body = JSON.stringify({
                email: inputUserNameRef.current.value,
                password: inputPasswordRef.current.value

            });

            ApiLoginUser(body, data => {
                if (data._id) {
                    setCurrentUser(data)
                } else {
                    setPassErrorMessage("Login error: user data or password wrong")
                }
            });
        }

    }

    return (
        <div ref={ref} className={styles.body}>

            <video id="background-video" className={styles.background} autoPlay loop playsInline muted>
                <source src={video} type="video/mp4" />
            </video>
            <KeyboardComponent visible ref={keyboardRef} x="1519" y="1520" width="2082px" height="651px" s="1" />
            <div className={styles.languageChoices}>
                <img src={enImg} className={styles.languageButton} onClick={() => setLanguage(en)} style={{ border: language == en ? "4px solid #FFFFFF" : "" }} />
                <img src={roImg} className={styles.languageButton} onClick={() => setLanguage(ro)} style={{ border: language == ro ? "4px solid #FFFFFF" : "" }} />
            </div>
            <div className={styles.headerText}>Login</div>
            {!passErrorMessage && <div className={styles.headerDetails}>{language.login_page.Please}</div>}
            <div className={styles.headerDetails}>{passErrorMessage}</div>
            <div className={styles.loginChoices}>
                {guestLogin && <div className={styles.guestLogin}>
                    <InputTextComponent ref={inputGuestNameRef} keyboardRef={keyboardRef} value={guestLogin.name} fontSize="40px" placeHolder="Name" styleType='POPUP' width="1500px" height="120px" />
                    <LightButtonComponent onClick={setGuestData} style={{ width: 281, height: 82, marginTop: -50 }}>
                        <Lottie
                            loop={true}
                            play={true}
                            animationData={arrowLeft}
                            style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                        />
                        <div className={styles.loginPos}> Login</div>
                    </LightButtonComponent>
                </div>}
                {!guestLogin && <div className={styles.guestLogin}>

                    <InputTextComponent ref={inputUserNameRef} keyboardRef={keyboardRef} value={userLogin ? userLogin.email : ""} fontSize="40px" placeHolder="Email" styleType='POPUP' width="1500px" height="120px" />
                    <InputTextComponent ref={inputPasswordRef} type="password" keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Password} fontSize="40px" styleType='POPUP' width="1500px" height="120px" />

                    <div className={styles.visitorDiv}>
                        <div className={styles.lineVisitor}></div>
                        <label className={styles.textChoice}> Or as visitor </label>
                    </div>

                    <InputTextComponent ref={inputVisitorNameRef} keyboardRef={keyboardRef} value="" placeHolder={language.login_page.Name} fontSize="40px" styleType='POPUP' width="1500px" height="120px" />


                    <LightButtonComponent onClick={setUserData} style={{ width: 281, height: 82, bottom: 80, position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }}>
                        <Lottie
                            loop={true}
                            play={true}
                            animationData={arrowLeft}
                            style={{ position: "absolute", width: 60, height: 35, left: 50 }}
                        />
                        <div className={styles.loginPos}> Login</div>
                    </LightButtonComponent>
                </div>}
            </div>

        </div>
    );

}

export default LoginComponent;