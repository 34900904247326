import { useRef, useContext, useState, useEffect, createRef } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import styles from './ParticipantPickerComponent.module.css'

import Lottie from 'react-lottie-player'
import add from '../../../graphics/conference add/add-animat.json'

import InitialsCircleComponent from '../../commons/InitialsCircleComponent';

import { useGesture } from 'use-gesture-pack';
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import { GetMaxZIndex } from 'use-gesture-pack/dist/utils/Tools';




const ParticipantPickerComponent = ({ setDataChangedLocal, setParticipantsToSaveFunc, participantsIds }) => {
    const indexPartRef = useRef()
    const defaultRef = useRef()
    const moveStarted = useRef()
    const participantButtonRef = useRef()
    const positionMovingCardRef = useRef()
    const filteredParticipantsListRef = useRef()
    const participantsToAddRef = useRef()
    const groupsRef = useRef()
    const currentGroup = useRef()

    const moveStartedGroup = useRef()
    const initialPointGroupRef = useRef()
    const positionMovingGroupRef = useRef()
    const addedParticipantsRef = useRef()

    const participantsIdsLengthRef = useRef()
    const participantsToSaveLengthRef = useRef()
    const currentParticipantToSave = useRef()
    const deleteParticipantRef = useRef()
    const participantsToSaveListRef = useRef()

    const [filterUsers, setFilterUsers] = useState("All")

    const { editConference } = useContext(ProjectDetailsContext);
    const { selectedProject, socketRef } = useContext(GlobalContext);

    const [participantsToAdd, setParticipantsToAdd] = useState([])
    const [participantsToSave, setParticipantsToSave] = useState([])
    const [filteredParticipants, setFilteredParticipants] = useState([])
    const [membersToAdd, setMembersToAdd] = useState([])
    const [groupsWidth, setGroupsWidth] = useState(2000)
    const [addButtonWidth, setAddButtonWidth] = useState(260)
    

    const participantsType = ["Guest", "Visitor", "Partner", "Member", "Administrator"]
    const colorsRole = ["#5F988F", "#5F988F", "#6B9A86", "#779D7E", "#849E75", "#91A16C"]
    const colorsDepartment = ["#41619B", "#52579E", "#634EA2", "#7544A6", "#7544A6", "#7544A6"]

    const filteredParticipantsRefs = useRef(filteredParticipants.map(() => createRef()));
    const groupRefs = useRef(participantsType.map(() => createRef()));
    const participantsToSaveRef = useRef(participantsToSave.map(() => createRef()));

    const dragGesture = true
    const appWidth = process.env.REACT_APP_WIDTH
    const appHeight = process.env.REACT_APP_HEIGHT
    const bringToFront = true

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    useGesture(
        {
            onDragStart: () =>{
                if(filteredParticipantsRefs.current[indexPartRef.current].current !== null){
                    filteredParticipantsRefs.current[indexPartRef.current].current.style.zIndex = GetMaxZIndex() + 1
                }
            },
            onDrag: ({ point, dx, dy }) => {
                console.log(filteredParticipantsRefs.current[indexPartRef.current])

                var mat = identity();

                if (!moveStarted.current) {
                    indexPartRef.current = Math.floor(point.local.x / 666)
                    moveStarted.current = true
                }

                if(filteredParticipantsRefs.current[indexPartRef.current].current !== null){
                    if (filteredParticipantsRefs.current[indexPartRef.current].current?.style.transform) {
                        mat = fromString(filteredParticipantsRefs.current[indexPartRef.current].current.style.transform);
                    }
                    var trans = translate(dx, dy);
                    var final = compose(trans, mat);
    
                    filteredParticipantsRefs.current[indexPartRef.current].current.style.transform = toString(final);
                }

                positionMovingCardRef.current = point.screen
                
                
            },
            onGestureEnded: () => {

                
                moveStarted.current = false

                let { x, y, width, height } = participantButtonRef.current.getBoundingClientRect()

                if(participantsIdsLengthRef.current > 0 || participantsToSaveLengthRef.current> 0){
                    x = addedParticipantsRef.current.getBoundingClientRect().x
                    y = addedParticipantsRef.current.getBoundingClientRect().y
                    width = addedParticipantsRef.current.getBoundingClientRect().width
                    height = addedParticipantsRef.current.getBoundingClientRect().height
                } 
                

                let posX = positionMovingCardRef.current?.x
                let posY = positionMovingCardRef.current?.y

                if (x / oxDivider - 20 <= posX && (x + width) / oxDivider + 20 >= posX && y / oyDivider - 20 <= posY && (y + height) / oyDivider + 20 >= posY) {
                    addParticipants(filteredParticipantsListRef.current[indexPartRef.current]._id)
                }

                if(filteredParticipantsRefs.current[indexPartRef.current].current !== null){
                    filteredParticipantsRefs.current[indexPartRef.current].current.style.transform = 'matrix(1,0,0,1,0,0)';
                }
                positionMovingCardRef.current = {x: 0, y: 0}
            }
        },
        {
            target: defaultRef,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    useGesture(
        {
            onDragStart: () =>{
                groupRefs.current[currentGroup.current].current.style.zIndex = GetMaxZIndex() + 1
            },
            onDrag: ({ point, dx, dy }) => {

                var mat = identity();

                if (initialPointGroupRef.current === -1) {
                    initialPointGroupRef.current = point.screen.x
                    let { x, width } = groupRefs.current[currentGroup.current].current.getBoundingClientRect()

                    if (x / oxDivider <= initialPointGroupRef.current && (x + width) / oxDivider >= initialPointGroupRef.current) {
                        moveStartedGroup.current = true
                    }
                }


                if (moveStartedGroup.current) {
                    if (groupRefs.current[currentGroup.current].current.style.transform !== '') {
                        mat = fromString(groupRefs.current[currentGroup.current].current.style.transform);
                    }
                    var trans = translate(dx, dy);
                    var final = compose(trans, mat);

                    groupRefs.current[currentGroup.current].current.style.transform = toString(final);
                }

                positionMovingGroupRef.current = point.screen

            },
            onGestureEnded: () => {
                moveStartedGroup.current = false
                initialPointGroupRef.current = -1

                let { x, y, width, height } = participantButtonRef.current.getBoundingClientRect()

                let posX = positionMovingGroupRef.current?.x
                let posY = positionMovingGroupRef.current?.y

                if (x / oxDivider <= posX && (x + width) / oxDivider >= posX && y / oyDivider <= posY && (y + height) / oyDivider >= posY) {
                    const ids = filteredParticipantsListRef.current.map(item => item._id);
                    addParticipants(ids)

                }

                groupRefs.current[currentGroup.current].current.style.transform = 'matrix(1,0,0,1,0,0)';

                positionMovingGroupRef.current = {x: 0, y: 0}
            }
        },
        {
            target: groupsRef,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    useGesture(
        {
            onDragStart: () => {
                if(currentParticipantToSave.current !== -1)
                    participantsToSaveRef.current[currentParticipantToSave.current].current.style.zIndex = GetMaxZIndex() + 1
                deleteParticipantRef.current = false
            },
            onDrag: ({ point, dx, dy }) => {

                console.log(currentParticipantToSave.current)

                if(currentParticipantToSave.current !== -1){
                    var mat = identity();

                    if (participantsToSaveRef.current[currentParticipantToSave.current].current.style.transform !== '') {
                        mat = fromString(participantsToSaveRef.current[currentParticipantToSave.current].current.style.transform);
                    }
    
                    var trans = translate(dx, dy);
                    var final = compose(trans, mat);
    
                    participantsToSaveRef.current[currentParticipantToSave.current].current.style.transform = toString(final);
    
                    if(point.screen.y >= 1960){
                        deleteParticipantRef.current = true
                    } 
                }
                 

                
            },
            onGestureEnded: () => {
                if(currentParticipantToSave.current !== -1){
                    if(deleteParticipantRef.current){
                        removeParticipant(participantsToSaveListRef.current[currentParticipantToSave.current]._id)
                    } else {
                        participantsToSaveRef.current[currentParticipantToSave.current].current.style.transform = "matrix(1,0,0,1,0,0)"
                    }
                }
                deleteParticipantRef.current = false
                
            }
        },
        {
            target: participantButtonRef,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    useEffect(() => {
        socketRef.current.emit("GET_CONFERENCE_PARTICIPANTS_TO_ADD", selectedProject._id, editConference ? editConference._id : null, callback => {
            setParticipantsToAdd(callback)
            participantsToAddRef.current = callback
            setFilteredParticipants(callback)
            setMembersToAdd(callback.filter((participant) => participant.role.toUpperCase() === "MEMBER" || participant.role.toUpperCase() === "ADMINISTRATOR"))
        })

        indexPartRef.current = 0
        moveStarted.current = false

        currentGroup.current = 0
        moveStartedGroup.current = false
        initialPointGroupRef.current = -1

        participantsIdsLengthRef.current = participantsIds.length
    }, [])

    useEffect(() => {
        let arr1 = Array(participantsToAdd.length + participantsToSave.length).fill(0);
        if (filteredParticipants.length > 0) {
            filteredParticipantsRefs.current = arr1.map((_, index) =>
                filteredParticipantsRefs.current[index] || createRef()
            )
        }

        filteredParticipantsListRef.current = filteredParticipants

        let arr = Array(1 + participantsType.length + departmentsToAdd().length).fill(0);
        groupRefs.current = arr.map((_, index) =>
            groupRefs.current[index] || createRef()
        )
    }, [filteredParticipants])

    useEffect(() => {
        setGroupsWidth(- groupsRef.current?.getBoundingClientRect().width / oxDivider)
    }, [participantsType])

    useEffect(() => {
        if(participantsIds.length > 0 || participantsToSave.length > 0){
            setAddButtonWidth(0)
        } else {
            setAddButtonWidth(- participantButtonRef.current?.getBoundingClientRect().width / oxDivider)
        }

        participantsToSaveLengthRef.current = participantsToSave.length
        participantsToSaveListRef.current = participantsToSave

        let arr = Array(1 + participantsToSave.length + participantsToAdd.length).fill(0);

        participantsToSaveRef.current = arr.map((_, index) =>
            participantsToSaveRef.current[index] || createRef()
        )
    }, [participantsToSave])

    const departmentsToAdd = () => {

        let departmentList = []

        membersToAdd.forEach(member => {
            if (!departmentList.includes(member.department.name)) {
                departmentList.push(member.department.name);
            }
        });

        return departmentList
    }

    function addParticipants(participantsIds) {
        setDataChangedLocal(true);

        const matchedParticipants = participantsToAddRef.current.filter(participant =>
            participantsIds.includes(participant._id)
        );

        setParticipantsToSave(prevParticipantsToSave => {
            const newParticipants = matchedParticipants.filter(
                participant => !prevParticipantsToSave.some(p => p._id === participant._id)
            );

            return [...prevParticipantsToSave, ...newParticipants];
        });

        setFilteredParticipants(filteredParticipants =>
            filteredParticipants.filter(participant =>
                !participantsIds.includes(participant._id)
            )
        );

        setParticipantsToAdd(participantsToAdd =>
            participantsToAdd.filter(participant =>
                !participantsIds.includes(participant._id)
            )
        );

        setMembersToAdd(membersToAdd =>
            membersToAdd.filter(member =>
                !participantsIds.includes(member._id)
            )
        );

        setParticipantsToSaveFunc(prevParticipantsToSave => {
            const newParticipants = matchedParticipants.filter(
                participant => !prevParticipantsToSave.some(p => p._id === participant._id))

            return [...prevParticipantsToSave, ...newParticipants];
        });
    };

    function countParticipantsType(type) {
        const typeSearch = type.toUpperCase()
        let count = 0
        for (let i = 0; i < participantsToAdd.length; i++) {
            if (typeSearch === participantsToAdd[i].role.toUpperCase()) {
                count++
            }
        }

        return count
    }

    function countParticipantsDepartment(dep) {
        const depSearch = dep.toUpperCase()
        let count = 0
        for (let i = 0; i < membersToAdd.length; i++) {
            if (depSearch === membersToAdd[i].department.name.toUpperCase()) {
                count++
            }
        }

        return count
    }

    function filterParticipants(filter, type, index) {
        if (type === 'ROLE') {
            setFilteredParticipants(participantsToAdd.filter((participant) => participant.role.toUpperCase() === filter.toUpperCase()))
        } else if (type === 'DEPARTMENT') {
            setFilteredParticipants(membersToAdd.filter((participant) => participant.department.name.toUpperCase() === filter.toUpperCase()))
        } else {
            setFilteredParticipants(participantsToAdd)
        }
        currentGroup.current = index
    }

    function removeParticipant(id) {
        const participantToRemove = participantsToSaveListRef.current.find(participant => participant._id === id);
        if (participantToRemove) {
            setParticipantsToSave(prevParticipantsToSave =>
                prevParticipantsToSave.filter(participant => participant._id !== id)
            );
        
            setFilteredParticipants(filteredParticipants => [
                ...filteredParticipants,
                participantToRemove
            ]);
        
            setParticipantsToAdd(participantsToAdd => [
                ...participantsToAdd,
                participantToRemove
            ]);

            if(participantToRemove.role.toUpperCase() === "MEMBER" || participantToRemove.role.toUpperCase() === "ADMINISTRATOR"){
                setMembersToAdd(membersToAdd => [
                    ...membersToAdd,
                    participantToRemove
                ]);
            }
        
            setParticipantsToSaveFunc(prevParticipantsToSave => 
                prevParticipantsToSave.filter(participant => participant._id !== id)
            );
        }
    }


    return (
        <>
            <div className={styles.divNrParticipants}>
                <label className={styles.nrParticipants}>{participantsToSave.length}</label>
                <label className={styles.lblParticipants}>Participants</label>
            </div>
            <div className={participantsToSave.length > 0 || participantsIds.length > 0 ? styles.backgroundParticipantsToSave : styles.addParticipantButton} ref={participantButtonRef}
                style={{ transform: `matrix(1,0,0,1,${addButtonWidth / 2},0)` }}>
                {participantsToSave.length === 0 && participantsIds.length === 0 &&
                    <Lottie
                        loop={true}
                        play={true}
                        animationData={add}
                        style={{ position: "absolute", width: 400, height: 268, left: -66 }}
                    />}
                {(participantsIds.length > 0 || participantsToSave.length > 0) && (
                    <div className={styles.addedParticipants} ref={addedParticipantsRef} >
                        {participantsIds.length > 0 && participantsIds.map((participant, index) => (
                            <div key={participant._id} onTouchStart={() => currentParticipantToSave.current = -1}>
                                <InitialsCircleComponent
                                    name={participant.name}
                                    style={{
                                        marginTop: 0,
                                        marginLeft: index === 0 ? 0 : -57,
                                        border: '6px solid #0D0032',
                                        width: 180,
                                        height: 180,
                                        font: "normal normal normal 102px/125px 'bio-sans'",
                                        
                                    }}
                                />
                            </div>
                        ))}

                        {participantsToSave.length > 0 && participantsToSave.map((participant, index) => (
                            <div key={participant._id} ref={participantsToSaveRef.current[index]} style={{transform: "matrix(1,0,0,1,0,0)", zIndex: GetMaxZIndex()}}
                            onTouchStart={() => {console.log(index);currentParticipantToSave.current = index}}>
                                <InitialsCircleComponent
                                    name={participant.name}
                                    style={{
                                        marginTop: 0,
                                        marginLeft: index === 0 && participantsIds.length === 0 ? 0 : -57,
                                        border: '6px solid #0D0032',
                                        width: 180,
                                        height: 180,
                                        font: "normal normal normal 102px/125px 'bio-sans'",
                                        // position: "absolute"
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.participantsToAdd}>
                <div className={styles.lineFolders} />
                <div className={styles.divGroups} ref={groupsRef} style={{ transform: `matrix(1,0,0,1,${groupsWidth / 2},0)` }}>

                    <div ref={groupRefs.current[0]} className={filterUsers === 'All' ? styles.groupClicked : styles.groupSimple}
                        onClick={() => { filterParticipants('ALL', 'ALL', 0); setFilterUsers('All') }} style={{ transform: "matrix(1,0,0,1,0,0)" }}>
                        <div className={filterUsers === 'All' ? styles.circleClicked : styles.circle}>
                            <label className={styles.textCircle}> {participantsToAdd.length} </label>
                        </div>
                        <label className={filterUsers === 'All' ? styles.textClicked : styles.textSimple}> All</label>
                    </div>
                    {participantsType.map((type, index) =>
                        <div key={index} ref={groupRefs.current[index + 1]} className={filterUsers === type ? styles.groupClicked : styles.groupSimple}
                            style={{ borderColor: colorsRole[index + 1], transform: "matrix(1,0,0,1,0,0)" }}
                            onClick={() => { filterParticipants(type.toUpperCase(), 'ROLE', index + 1); setFilterUsers(type) }}>
                            <div className={filterUsers === type ? styles.circleClicked : styles.circle} style={{ backgroundColor: filterUsers === type ? '' : colorsRole[index + 1] }} >
                                <label className={styles.textCircle}> {countParticipantsType(type)} </label>
                            </div>
                            <label className={filterUsers === type ? styles.textClicked : styles.textSimple}> {type}</label>
                        </div>)}
                    {departmentsToAdd().map((department, index) =>
                        <div key={index} ref={groupRefs.current[index + 1 + participantsType.length]} className={filterUsers === department ? styles.groupClicked : styles.groupSimple}
                            style={{ borderColor: colorsDepartment[index], transform: "matrix(1,0,0,1,0,0)" }}
                            onClick={() => { filterParticipants(department.toUpperCase(), 'DEPARTMENT', index + 1 + participantsType.length); setFilterUsers(department) }}>
                            <div className={filterUsers === department ? styles.circleClicked : styles.circle} style={{ backgroundColor: filterUsers === department ? '' : colorsDepartment[index] }}>
                                <label className={styles.textCircle}> {countParticipantsDepartment(department)} </label>
                            </div>
                            <label className={filterUsers === department ? styles.textClicked : styles.textSimple}> {department}</label>
                        </div>)}
                </div>
                <div className={styles.participantsList} ref={defaultRef} style={{ transform: "matrix(1,0,0,1,0,0)", overflow: "visible" }}>
                    {filteredParticipants.map((item, index) => (

                        <div key={index} className={styles.participantCard} ref={filteredParticipantsRefs.current[index]} style={{ transform: "matrix(1,0,0,1,0,0)" }}>
                            <div className={styles.participantCircle}>
                                <div className={styles.partcipantInitials}>
                                    {(() => {
                                        const words = item.name.split(' ');
                                        if (words.length === 1) {
                                            return words[0].charAt(0).toUpperCase();
                                        } else {
                                            return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className={styles.infoParticipants}>
                                <label className={styles.typeName}>{item.role}</label>
                                <label className={styles.nameLabel}>{item.name}</label>
                                {item.role === 'MEMBER' && (
                                    <div className={styles.fundepDiv}>
                                        <label className={styles.funcLabel}>{item.function?.name}</label>
                                        <label className={styles.funcLabel} style={{ marginLeft: 10, marginRight: 10 }}> | </label>
                                        <label className={styles.depLabel}>{item.department?.name}</label>  {/* Safe access with optional chaining */}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.lineFolders} style={{ top: 313 }} />
            </div>
        </>

    );

}

export default ParticipantPickerComponent;