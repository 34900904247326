import styles from './DatePickerComponent.module.css'

import { generateDateList, compareDate } from '../../../utils/UtilitaryFunctions';



const DatePickerComponent = ({calendarRef, selectedDate, setSelectedDate, dataChanged, firstScrollDays}) => {

    const currentDate = new Date();
    const dateList = generateDateList(currentDate);

    


    const handleScrollDay = () => {
        if(firstScrollDays.current !== true){
            dataChanged.current = true
        }

        if (calendarRef.current) {
            const calendarDays = calendarRef.current.children;

            for (let i = 0; i < calendarDays.length; i++) {
                const rect = calendarDays[i].getBoundingClientRect();

                if (rect.left >= -15) {
                    const date = new Date(currentDate);
                    date.setDate(currentDate.getDate() + i);

                    const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    const d2 = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

                    if (d1.getTime() !== d2.getTime())
                        setSelectedDate(date)

                    break;
                }
            }
        }
        firstScrollDays.current = false
    };


    return (
        <>
            <div className={styles.selectedCalendarDay}></div>

            {selectedDate !== undefined && <div className={styles.calendar} ref={calendarRef} onScroll={handleScrollDay}>
                {dateList.map((item, index) => (
                    <div key={index} className={styles.calendarDay} style={{ color: compareDate( selectedDate, item.date) ? "#050020" : "" }}>
                        <div className={styles.calendarMonth}>{item.dayNumber === "01" ? item.month : ""}</div>
                        <div className={styles.calendarDayNumber}>{item.dayNumber}</div>
                        <div className={styles.calendarDayOfWeek}>{item.dayOfWeek}</div>
                    </div>
                ))}
            </div> }
        </>
    );

}

export default DatePickerComponent;