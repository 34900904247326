import { useEffect, useRef } from "react";
import io from "socket.io-client";
import { Device } from 'mediasoup-client'

import { GlobalContext } from "../../contexts/globalContext"
import { useContext } from 'react';

function MediasoupConferenceComponent() {

    const { currentRoom } = useContext(GlobalContext);

    const socketRoomRef = useRef()
    const localVideoRef = useRef()
    const remoteVideosContainerRef = useRef()

    let params = {
        encodings: [
            {
                rid: 'r0',
                maxBitrate: 100000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r1',
                maxBitrate: 300000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r2',
                maxBitrate: 900000,
                scalabilityMode: 'S1T3',
            },
        ],
        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
        codecOptions: {
            videoGoogleStartBitrate: 1000
        }
    }

    let audioParams;
    let videoParams = { params };
    let consumingTransports = [];

    let device;
    let rtpCapabilities;
    let producerTransport;
    let consumerTransports = [];
    let audioProducer;
    let videoProducer;

    useEffect(() => {
        socketRoomRef.current = io.connect("https://localhost:3001/mediasoup", { secure: true, reconnect: true, rejectUnauthorized: false })

        // console.log(socketRoomRef.current)

        socketRoomRef.current.on('connection-success', ({ socketId }) => {
            console.log(socketId)
            GetLocalStream()
        })

        socketRoomRef.current.on('new-producer', ({ producerId }) => signalNewConsumerTransport(producerId))

        socketRoomRef.current.on('producer-closed', ({ remoteProducerId }) => {
            // server notification is received when a producer is closed
            // we need to close the client-side consumer and associated transport
            const producerToClose = consumerTransports.find(transportData => transportData.producerId === remoteProducerId)
            producerToClose.consumerTransport.close()
            producerToClose.consumer.close()
        
            // remove the consumer transport from the list
            consumerTransports = consumerTransports.filter(transportData => transportData.producerId !== remoteProducerId)
        
            // remove the video div element
            remoteVideosContainerRef.current.removeChild(document.getElementById(`td-${remoteProducerId}`))
        })
    }, [])

    const GetLocalStream = () => {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
                width: {
                    min: 640,
                    max: 1920,
                },
                height: {
                    min: 400,
                    max: 1080,
                }
            }
        })
            .then(StreamSuccess)
            .catch(error => {
                console.log(error.message)
            })
    }

    const StreamSuccess = (stream) => {
        console.log("StreamSuccess")
        console.log(localVideoRef.current)
        localVideoRef.current.srcObject = stream;

        audioParams = { track: stream.getAudioTracks()[0], ...audioParams };
        videoParams = { track: stream.getVideoTracks()[0], ...videoParams };

        console.log(audioParams);
        console.log(videoParams);

        JoinRoom();
    }

    const JoinRoom = () => {
        socketRoomRef.current.emit('joinRoom', { roomName: currentRoom  }, (data) => {
            console.log(`Router RTP Capabilities... ${data.rtpCapabilities}`)
            // we assign to local variable and will be used when
            // loading the client Device (see createDevice above)
            rtpCapabilities = data.rtpCapabilities

            // once we have rtpCapabilities from the Router, create Device
            CreateDevice()
        })
    }

    const CreateDevice = async () => {
        try {
            console.log(Device);
            device = new Device()
    
            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
            // Loads the device with RTP capabilities of the Router (server side)
            await device.load({
                // see getRtpCapabilities() below
                routerRtpCapabilities: rtpCapabilities
            })
    
            console.log('Device RTP Capabilities', device.rtpCapabilities)
    
            // once the device loads, create transport
            CreateSendTransport()
    
        } catch (error) {
            console.log(error)
            if (error.name === 'UnsupportedError')
                console.warn('browser not supported')
        }
    }

    const CreateSendTransport = () => {
        // see server's socket.on('createWebRtcTransport', sender?, ...)
        // this is a call from Producer, so sender = true
        socketRoomRef.current.emit('createWebRtcTransport', { consumer: false }, ({ params }) => {
            // The server sends back params needed 
            // to create Send Transport on the client side
            if (params.error) {
                console.log(params.error)
                return
            }
    
            console.log(params)
    
            // creates a new WebRTC Transport to send media
            // based on the server's producer transport params
            // https://mediasoup.org/documentation/v3/mediasoup-client/api/#TransportOptions
            producerTransport = device.createSendTransport(params)
    
            // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
            // this event is raised when a first call to transport.produce() is made
            // see connectSendTransport() below
            producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    // Signal local DTLS parameters to the server side transport
                    // see server's socket.on('transport-connect', ...)
                    await socketRoomRef.current.emit('transport-connect', {
                        dtlsParameters,
                    })
    
                    // Tell the transport that parameters were transmitted.
                    callback()
    
                } catch (error) {
                    errback(error)
                }
            })
    
            producerTransport.on('produce', async (parameters, callback, errback) => {
                console.log(parameters)
    
                try {
                    // tell the server to create a Producer
                    // with the following parameters and produce
                    // and expect back a server side producer id
                    // see server's socket.on('transport-produce', ...)
                    await socketRoomRef.current.emit('transport-produce', {
                        kind: parameters.kind,
                        rtpParameters: parameters.rtpParameters,
                        appData: parameters.appData,
                    }, ({ id, producersExist }) => {
                        // Tell the transport that parameters were transmitted and provide it with the
                        // server side producer's id.
                        callback({ id })
    
                        // if producers exist, then join room
                        if (producersExist) getProducers()
                    })
                } catch (error) {
                    errback(error)
                }
            })
    
            connectSendTransport()
        })
    }

    const connectSendTransport = async () => {
        // we now call produce() to instruct the producer transport
        // to send media to the Router
        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
        // this action will trigger the 'connect' and 'produce' events above
    
        audioProducer = await producerTransport.produce(audioParams);
        videoProducer = await producerTransport.produce(videoParams);
    
        audioProducer.on('trackended', () => {
            console.log('audio track ended')
    
            // close audio track
        })
    
        audioProducer.on('transportclose', () => {
            console.log('audio transport ended')
    
            // close audio track
        })
    
        videoProducer.on('trackended', () => {
            console.log('video track ended')
    
            // close video track
        })
    
        videoProducer.on('transportclose', () => {
            console.log('video transport ended')
    
            // close video track
        })
    }
    
    const signalNewConsumerTransport = async (remoteProducerId) => {
        //check if we are already consuming the remoteProducerId
        if (consumingTransports.includes(remoteProducerId)) return;
        consumingTransports.push(remoteProducerId);
    
        await socketRoomRef.current.emit('createWebRtcTransport', { consumer: true }, ({ params }) => {
            // The server sends back params needed 
            // to create Send Transport on the client side
            if (params.error) {
                console.log(params.error)
                return
            }
            console.log(`PARAMS... ${params}`)
    
            let consumerTransport
            try {
                consumerTransport = device.createRecvTransport(params)
            } catch (error) {
                // exceptions: 
                // {InvalidStateError} if not loaded
                // {TypeError} if wrong arguments.
                console.log(error)
                return
            }
    
            consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    // Signal local DTLS parameters to the server side transport
                    // see server's socket.on('transport-recv-connect', ...)
                    await socketRoomRef.current.emit('transport-recv-connect', {
                        dtlsParameters,
                        serverConsumerTransportId: params.id,
                    })
    
                    // Tell the transport that parameters were transmitted.
                    callback()
                } catch (error) {
                    // Tell the transport that something was wrong
                    errback(error)
                }
            })
    
            connectRecvTransport(consumerTransport, remoteProducerId, params.id)
        })
    }

    // server informs the client of a new producer just joined
    

    const getProducers = () => {
        socketRoomRef.current.emit('getProducers', producerIds => {
            console.log(producerIds)
            // for each of the producer create a consumer
            // producerIds.forEach(id => signalNewConsumerTransport(id))
            producerIds.forEach(signalNewConsumerTransport)
        })
    }

    const connectRecvTransport = async (consumerTransport, remoteProducerId, serverConsumerTransportId) => {
        // for consumer, we need to tell the server first
        // to create a consumer based on the rtpCapabilities and consume
        // if the router can consume, it will send back a set of params as below
        await socketRoomRef.current.emit('consume', {
            rtpCapabilities: device.rtpCapabilities,
            remoteProducerId,
            serverConsumerTransportId,
        }, async ({ params }) => {
            if (params.error) {
                console.log('Cannot Consume')
                return
            }
    
            console.log(`Consumer Params ${params}`)
            // then consume with the local consumer transport
            // which creates a consumer
            const consumer = await consumerTransport.consume({
                id: params.id,
                producerId: params.producerId,
                kind: params.kind,
                rtpParameters: params.rtpParameters
            })
    
            consumerTransports = [
                ...consumerTransports,
                {
                    consumerTransport,
                    serverConsumerTransportId: params.id,
                    producerId: remoteProducerId,
                    consumer,
                },
            ]
    
            // create a new div element for the new consumer media
            const newElem = document.createElement('div')
            newElem.setAttribute('id', `td-${remoteProducerId}`)
    
            if (params.kind == 'audio') {
                //append to the audio container
                newElem.innerHTML = '<audio id="' + remoteProducerId + '" autoplay></audio>'
            } else {
                //append to the video container
                newElem.setAttribute('class', 'remoteVideo')
                newElem.innerHTML = '<video id="' + remoteProducerId + '" autoplay class="video" ></video>'
            }
    
            remoteVideosContainerRef.current.appendChild(newElem)
    
            // destructure and retrieve the video track from the producer
            const { track } = consumer
    
            document.getElementById(remoteProducerId).srcObject = new MediaStream([track])
    
            // the server consumer started with media paused
            // so we need to inform the server to resume
            socketRoomRef.current.emit('consumer-resume', { serverConsumerId: params.serverConsumerId })
        })
    }
    
    



    return (
        <div>
            <video ref={localVideoRef} width={800} height={800} autoPlay></video>
            <div ref={remoteVideosContainerRef}></div>
        </div>
    );
}

export default MediasoupConferenceComponent;
