import { useState, useRef, createContext } from "react"
import en from "../langs/en.json"

export const GlobalContext = createContext([{}, () => {}])

export const GlobalProvider = ( props ) => {
    // general variables
    const socketRef = useRef();
    const selectedTopicIdRef = useRef();
    const [language, setLanguage] = useState(en)
    const [usingInvitation, setUsingInvitation] = useState(false)
    const [currentProject, setCurrentProject] = useState()
    const [projectsList, setProjectsList] = useState()
    const [selectedProject, setSelectedProject] = useState()

    const [allUsers, setAllUsers] = useState()
    const [upcomingConferences, setUpcomingConferences] = useState([])
    const [notificationUpcomingConferences, setNotificationUpcomingConferences] = useState([])
    const [historyConferences, setHistoryConferences] = useState([])
    const [statusProjectList, setStatusProjectList] = useState(true) // active = true, inactive = false, all = null
    const [detailsUpcomingConference, setDetailsUpcomingConference] = useState()
    const [guestLogin, setGuestLogin] = useState()
    const [userLogin, setUserLogin] = useState()
    const [seeUserDetails, setSeeUserDetails] = useState()
    const [topicFiles, setTopicFiles] = useState()
    const [selectedParentForFiles, setSelectedParentForFiles] = useState()
    const [selectedRefTypeForFiles, setSelectedRefTypeForFiles] = useState()
    const [conferenceScreen, setConferenceScreen] = useState()
    const [currentConference, setCurrentConference] = useState()
    const [selectedTopic, setSelectedTopic] = useState()

    const [departmentList, setDepartmentList] = useState()
    const [functionList, setFunctionList] = useState()

    // login variables
    const [currentUser, setCurrentUser] = useState()

    const [ currentRoom, setCurrentRoom] = useState(null);
    

    return (
        <GlobalContext.Provider value={{
            socketRef,
            selectedTopicIdRef,
            language, setLanguage,
            currentUser, setCurrentUser,
            usingInvitation, setUsingInvitation,
            currentProject, setCurrentProject,
            projectsList, setProjectsList,
            selectedProject, setSelectedProject,
            allUsers, setAllUsers,
            upcomingConferences, setUpcomingConferences,
            notificationUpcomingConferences, setNotificationUpcomingConferences,
            historyConferences, setHistoryConferences,
            statusProjectList, setStatusProjectList,
            detailsUpcomingConference, setDetailsUpcomingConference,
            guestLogin, setGuestLogin,
            userLogin, setUserLogin,
            seeUserDetails, setSeeUserDetails,
            topicFiles, setTopicFiles,
            selectedParentForFiles, setSelectedParentForFiles,
            selectedRefTypeForFiles, setSelectedRefTypeForFiles,
            conferenceScreen, setConferenceScreen,
            currentConference, setCurrentConference,
            selectedTopic, setSelectedTopic,
            departmentList, setDepartmentList,
            functionList, setFunctionList,
            currentRoom, setCurrentRoom
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}