import { useRef, useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../contexts/globalContext";

import { ProjectDetailsContext } from "../contexts/projectDetailsContext";


import styles from './ProjectDetailsComponent.module.css'

import AddGuestComponent from './popup/AddGuestsComponent';
import AddTopicsComponent from './popup/AddTopicsComponent';
import AddFilesComponent from './popup/AddFilesComponent';
import AddPartipicantsComponent from './popup/AddParticipantsComponent';
// import AddConferenceComponent from './popup/AddConferencePopup';
import AddConferenceComponent from './popup/AddConferenceComponent'
import AddConferenceComponentNew from './popup/AddConferenceComponentNew';
import EditNameComponent from './popup/EditNameComponent';
import ConferenceListComponent from './projectDetails/conferences/PD_ConferenceListComponent';
import ParticipantListComponent from './projectDetails/participants/PD_ParticipantListComponent';
import FileListComponent from './projectDetails/files/PD_FileListComponent';
import TopicListComponent from './projectDetails/topics/PD_TopicListComponent';
import AddNewDetailsComponent from './projectDetails/AddNewDetailsComponent';
import MoreMenuComponent from './commons/MoreMenuComponent';
import UserDetailsComponent from './UserDetailsComponent';
import ConferenceScreenComponent from './conferenceScreen/ConferenceScreenComponent';
import AddMembersComponent from './popup/AddMembersComponent';
import AddPartnersComponent from './popup/AddPartnersComponent';
import AddVisitorsComponent from './popup/AddVisitorsComponent';
import HeaderComponent from './commons/HeaderComponent';
import NotificationComponent from './NotificationComponent';


const ProjectDetailsComponent = () => {

    const ref = useRef()

    const [seeNotification, setSeeNotification] = useState(false)

    const { seeUserDetails, setSelectedParentForFiles, selectedProject, conferenceScreen } = useContext(GlobalContext);
    const { 
        addGuest, 
        addTopic, 
        addFiles, 
        addParticipants, 
        editName,
        createConference,
        editConference,
        editTopic,
        moreClicked, setMoreClicked,
        addMembers,
        addVisistors,
        addPartners
    } = useContext(ProjectDetailsContext);

    useEffect(() => {
        if(selectedProject.topics && selectedProject.topics.length > 0){
            setSelectedParentForFiles(selectedProject.topics[0]._id)
        }
    }, [])

    

    
    return (
        <div ref={ref} className={styles.project_component}>
            {/* <ClosePageButtonComponent setClose={handleCloseProject} /> */}
            
            <div className={styles.topShadow}/>
            {/* <HeaderDetailsComponent/> */}
            <HeaderComponent setSeeNotification={setSeeNotification} seeNotification={seeNotification}/>
            {seeNotification && <NotificationComponent/>}
            <div className={styles.lists}>
                <ConferenceListComponent/>
                <ParticipantListComponent/>
                <TopicListComponent/>
                <FileListComponent/>
            </div>
            <div className={styles.bottomShadow}/>
            {seeUserDetails && <UserDetailsComponent/>}
            {moreClicked?.shown == true && <MoreMenuComponent moreClicked={moreClicked} setMoreClicked={setMoreClicked}/>}
            <AddNewDetailsComponent/>
            
            {addTopic && <AddTopicsComponent />}
            {addFiles && <AddFilesComponent/>}
            {addParticipants && <AddPartipicantsComponent/>}
            {createConference && <AddConferenceComponentNew/>} 
            {editName && <EditNameComponent />}         
            {editConference && <AddConferenceComponentNew/>}
            {editTopic && <AddTopicsComponent />}
            {conferenceScreen && <ConferenceScreenComponent/>}
            {addGuest && <AddGuestComponent/>}
            {addMembers && <AddMembersComponent/>}
            {addPartners && <AddPartnersComponent/>}
            {addVisistors && <AddVisitorsComponent/>}
        </div>
    );

}

export default ProjectDetailsComponent;