

import styles from './PopupBaseComponent.module.css'
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions'


const PopupBaseComponent = ({children, flexDirection = ""}) => {
    
    return (
        <div className={styles.component} style={{zIndex: GetMaxZIndex() + 1, flexDirection: flexDirection}}>
            {children}
        </div>
    );

}

export default PopupBaseComponent;