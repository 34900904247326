import React, { useContext, useEffect } from 'react';

import styles from './HourPickerComponent.module.css'

import { generateHourList } from '../../../utils/UtilitaryFunctions';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';


const HourPickerComponent = ({ initialHourEndRef, initialHourStartRef, selectedHourRef, dataChanged, firstScrollHours, 
                                selectedHourStart, setSelectedHourStart, setSelectedHourEnd, duration, hoursRef, 
                                selectedHoursRef, applyColorChangesSelectedHours, dayConferences}) => {

    const hourList = generateHourList();

    const {editConference} = useContext(ProjectDetailsContext)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;


    function computeStartingHour(i) {
        let leftPosHour = selectedHourRef.current.getBoundingClientRect().left / oxDivider - 65

        let addHour = 6

        if (leftPosHour < 2540 && leftPosHour >= 2344) {
            addHour = 5
        } else if (leftPosHour < 2344) {
            addHour = 4
        } else if (leftPosHour >= 2736) {
            addHour = 7
        }

        let hour = i + addHour

        if (hour < 10) hour = `0${hour}`
        else hour = `${hour}`

        initialHourStartRef.current = i + 6

        return hour + ':' + selectedHourStart.split(':')[1]
    }

    function computeEndingHour(hourMinutes, i) {
        let [startHours, startMinutes] = hourMinutes.split(':').map(Number);
        let [durationHours, durationMinutes] = duration.split(':').map(Number);

        let endHours = startHours + durationHours;
        let endMinutes = startMinutes + durationMinutes;

        if (endMinutes >= 60) {
            endHours += Math.floor(endMinutes / 60);
            endMinutes = endMinutes % 60;
        }

        const formattedEndHours = String(endHours).padStart(2, '0');
        const formattedEndMinutes = String(endMinutes).padStart(2, '0');

        initialHourEndRef.current = i + 7

        return `${formattedEndHours}:${formattedEndMinutes}`
    }

    function computeScrollIntervalChange(i) {

        let hourMinutes = computeStartingHour(i)

        let hourMinutesEnd = computeEndingHour(hourMinutes, i)

        setSelectedHourStart(hourMinutes)
        setSelectedHourEnd(hourMinutesEnd)

    }

    const handleScrollHour = () => {
        
        if(firstScrollHours.current !== true){
            dataChanged.current = true
        }

        if (hoursRef.current) {
            const hours = hoursRef.current.children;

            if (selectedHoursRef.current) {
                selectedHoursRef.current.scrollLeft = hoursRef.current.scrollLeft;
                applyColorChangesSelectedHours()
            }


            for (let i = dayConferences.length; i < hours.length; i++) {
                const rect = hours[i].getBoundingClientRect();

                if (rect.left >= -40) {
                    computeScrollIntervalChange(i - dayConferences.length)
                    
                    break;
                }
            }
        }
        firstScrollHours.current = false

    }

    function computeWidthDayConf(duration){
        const width = duration / 60  * 182;

        return width
    }

    function computePostionDayConf(scheduleDate){

        let startDate = new Date(scheduleDate);

        let dayStart = new Date(scheduleDate);
        dayStart.setHours(0, 0, 0);

        let diffMs = startDate - dayStart;

        const position = (diffMs / 3600000) * 182 + 1450;

        return position
    }


    return (
        <>
            <div ref={selectedHourRef} className={styles.selectedHour} 
            style={{backgroundColor: editConference && !dataChanged.current ? "#FFFFFF" : "", background: editConference && !dataChanged.current ? "#FFFFFF" : ""}}/>

            <div ref={hoursRef} className={styles.hours} onScroll={handleScrollHour}>
                {dayConferences.length > 0 && dayConferences.map((conference, index) =>
                    <div className={styles.dayConferenceHour} key={index} 
                        style = {{ 
                            left: computePostionDayConf(conference.scheduleDate), 
                            width: computeWidthDayConf(conference.duration),
                            opacity: conference?._id === editConference?._id ? "0" : "1"
                        }}
                    />)
                }
                {hourList.map((time, index) => {
                    const [hours, minutes] = time.split(':');
                    if (index >= 8 && index <= 32) {
                        return (
                            <div className={styles.time} key={index} >
                                {hours}
                                <div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    } else {
                        return (
                            <div className={styles.time} key={index} style={{ opacity: 0 }}>
                                {hours}
                                <div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    }

                })}
                
            </div>

            <div ref={selectedHoursRef} className={styles.selectedHours}>
                {hourList.map((time, index) => {
                    const [hours, minutes] = time.split(':');
                    if (index >= 8 && index <= 32) {
                        return (
                            <div className={styles.time} key={index}>
                                {hours}
                                <div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    } else {
                        return (
                            <div className={styles.time} key={index} style={{ opacity: 0 }}>
                                {hours}
                                <div className={styles.minutes}>:{minutes}</div>
                            </div>
                        )
                    }
                })}
            </div>

        </>
    );

}

export default HourPickerComponent;