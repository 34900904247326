import { useContext, useEffect, useRef } from 'react';
import styles from './TimePickerComponent.module.css'


import { useGesture } from 'use-gesture-pack';
import { translate, compose, toString, identity, fromString } from 'transformation-matrix';
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

const TimePickerComponent = ({initialHourStartRef, selectedHourRef, selectedHourStart, setSelectedHourStart,
                                selectedHourEnd, setSelectedHourEnd, dataChanged }) => {

    const ref = useRef()
    const initialPointRef = useRef()
    const referencedWidth = useRef()
    const widthRef = useRef()

    const dragGesture = true
    const appWidth = process.env.REACT_APP_WIDTH
    const appHeight = process.env.REACT_APP_HEIGHT
    const bringToFront = true

    const {editConference} = useContext(ProjectDetailsContext)

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;


    useGesture(
        {
            onDrag: ({ point, dx, dy }) => {
                
                var mat = identity();

                if (ref.current.style.transform !== '') {
                    mat = fromString(ref.current.style.transform);
                }
                
                if(initialPointRef.current === -1){
                    dataChanged.current = true
                    initialPointRef.current = point.local.x
                    referencedWidth.current = widthRef.current

                } else{
                    if(initialPointRef.current < 261 && widthRef.current - dx > 522){

                        var trans = translate(dx, 0);
                        var final = compose(trans, mat);

                        ref.current.style.transform = toString(final);
                        
                        widthRef.current = widthRef.current - dx
                        computeTime()

                        moveRectangle(dx)

                    } else if(initialPointRef.current > referencedWidth.current - 260 && widthRef.current + dx > 522){
                        widthRef.current = widthRef.current + dx
                        computeTime()
                    }
                }

            },
            onGestureEnded: () => {
                initialPointRef.current = -1
            }
        },
        {
            target: ref,
            dragGesture,
            appWidth,
            appHeight,
            bringToFront
        }
    );

    const moveRectangle = (dx) => {
        var matRectangle = identity();

        if (selectedHourRef.current.style.transform !== '') {
            matRectangle = fromString(selectedHourRef.current.style.transform);
        }

        var transRectangle = translate(dx / 1302 * 153, 0);
        var finalRectangle = compose(transRectangle, matRectangle);
        selectedHourRef.current.style.transform = toString(finalRectangle);
    }

    const moveStart = () => {
        var matRectangle = identity();

        if (selectedHourRef.current.style.transform !== '') {
            matRectangle = fromString(selectedHourRef.current.style.transform);
        }

        let diffMinutes = parseInt(selectedHourStart.split(':')[1])

        var transRectangle = translate(diffMinutes / 60 * 1302, 0);
        var finalRectangle = compose(transRectangle, matRectangle);
        ref.current.style.transform = toString(finalRectangle);
        
    }

    const convertTimeStringToDate = (timeString) => {
        const [hours, minutes] = timeString.split(":");

        const now = new Date();

        now.setHours(parseInt(hours));
        now.setMinutes(parseInt(minutes));
        now.setSeconds(0);

        return now;
    };

    const convertDateToTimeString = (date) => {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${hours}:${minutes}`;
    };

    function computeTime(){
        
        const durationInHours = widthRef.current / 1302
        const startDeviationInHours = (ref.current.getBoundingClientRect().x / oxDivider - 2604) / 1302

        let startTime = convertTimeStringToDate(`${initialHourStartRef.current}:00`);

        const deviationInMilliseconds = startDeviationInHours * 60 * 60 * 1000;
        startTime = new Date(startTime.getTime() + deviationInMilliseconds);

        const durationInMilliseconds = durationInHours * 60 * 60 * 1000;
        const endTime = new Date(startTime.getTime() + durationInMilliseconds);

        setSelectedHourStart(convertDateToTimeString(startTime));
        setSelectedHourEnd(convertDateToTimeString(endTime));

    }

    useEffect(() => {
        initialPointRef.current = -1
        if(editConference){
            referencedWidth.current = 1302 * editConference.duration / 60
            widthRef.current = 1302 * editConference.duration / 60
            moveStart()
        } else {
            referencedWidth.current = 1302 
            widthRef.current = 1302 
        }
        
        
    },[])




    return (

            <div className={styles.durationChangeLine}>
                <div className={styles.line}></div>

                <div ref={ref} className={styles.referencedDiv} style={{transform: `matrix(1,0,0,1,0,0)`, width: widthRef.current}}>
                    <svg width="0" height="0">
                        <defs>
                            <clipPath id="set-ora-stang">
                                <path d="M261.565,76h0a92.019,92.019,0,0,1-72.747-35.142,105.5,105.5,0,1,0-.9,130.418A94.249,94.249,0,0,1,261.565,136ZM105.5,181A75.5,75.5,0,1,1,181,105.5v0A75.586,75.586,0,0,1,105.5,181"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div className={styles.gradientDiv} style={{ clipPath: 'url(#set-ora-stang)', backgroundColor: editConference && !dataChanged.current ? "#FFFFFF" : "", background: editConference && !dataChanged.current ? "#FFFFFF" : ""}}/>

                    <div className={styles.gradientDreptDiv} style={{ clipPath: 'url(#set-ora-stang)', backgroundColor: editConference && !dataChanged.current ? "#FFFFFF" : "", background: editConference && !dataChanged.current ? "#FFFFFF" : ""}}/>

                    <div className={styles.gradientDreptDiv} style={{ clipPath: `polygon(259px 75.5px, ${widthRef.current - 259}px 75.5px, ${widthRef.current - 259}px 135.5px, 259px 135.5px)`, backgroundColor: editConference && !dataChanged.current ? "#FFFFFF" : "", background: editConference && !dataChanged.current ? "#FFFFFF" : ""}}/>
                    
                    <div className={styles.durationStart}><span style={{ fontWeight: 'bold' }}>{selectedHourStart.split(':')[0]}</span>:{selectedHourStart.split(':')[1]}</div>

                    <div className={styles.durationEnd}><span style={{ fontWeight: 'bold' }}>{selectedHourEnd.split(':')[0]}</span>:{selectedHourEnd.split(':')[1]}</div>
                    
                </div>

            </div>
            
    );

}

export default TimePickerComponent;